.maincontainer {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: "Jost", sans-serif;
  background-image: url("../../img/FONDO_LOGIN.png");
  background-size: cover;
  
}
.main {
  width: 350px;
  height: 500px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 5px 20px 50px #000;
  background: #080404;
}
.label {
  color: #fff;
  font-size: 1.3em;
  justify-content: center;
  display: flex;
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 20px;
  cursor: pointer;
}
.div {
  display: flex;
}
.button {
  width: 60%;
  height: 40px;
  margin: 10px auto;
  justify-content: center;
  display: block;
  color: #fff;
  background: #303484;
  font-size: 1em;
  font-weight: bold;
  margin-top: 20px;
  outline: none;
  border: none;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
}
.button:hover {
  background: #6e70a8;
}
.cogs {
  color: #e0dede;
  margin: auto;
  margin-top: 30px;
  display: block;
}
