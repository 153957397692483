/* Breadcrumb.module.css */

/* Estilo de la barra de navegación (navbar) */
.navbar {
  display: flex;            /* Utiliza flexbox para el layout */
  flex-direction: row;      /* Alinea los elementos en fila */
  list-style-type: none;   /* Sin viñetas */
  padding: 10px;           /* Espaciado interno de la barra */
  margin: 0;               /* Sin margen */
  background-color: #2d3182; /* Color de fondo más suave */
  border-radius: 0px;      /* Bordes rectos */
}

/* Estilo de cada elemento de la barra de navegación */
.navbar li {
  margin-bottom: 0;        /* Asegúrate de no tener margen en la parte inferior */
  position: relative;      /* Para el dropdown que se posiciona de forma absoluta */
}

/* Estilo de los enlaces en la barra de navegación */
.navbar a {
  text-decoration: none;    /* Sin subrayado en los enlaces */
  color: #ffffff;          /* Color blanco para los enlaces */
  transition: color 0.3s;  /* Transición suave para el cambio de color */
}

/* Efecto al pasar el mouse sobre los enlaces de la barra de navegación */
.navbar a:hover {
  color: #a5a5a5;          /* Color gris claro al pasar el mouse */
}

/* Estilo del dropdown */
.dropdown {
  position: absolute;       /* Posicionamiento absoluto para el dropdown */
  background-color: #2d3182; /* Color de fondo del dropdown */
  margin: 0;               /* Sin margen */
  padding: 0;              /* Sin padding para el dropdown */
  border-radius: 3px;      /* Bordes redondeados del dropdown */
  z-index: 1;              /* Asegura que el dropdown esté por encima de otros elementos */
  display: none;           /* Ocultar el dropdown por defecto */
  list-style-type: none;   /* Sin viñetas en el dropdown */
}

/* Mostrar el dropdown al pasar el mouse sobre el elemento de la navbar */
.navbar li .dropdown {
  display: block;          /* Mostrar el dropdown */
}

/* Estilo del separador (>) después de cada elemento de la barra de navegación */
.navbar li::after {
  content: ' > ';          /* Usa ::after para insertar el separador */
  color: #ffffff;          /* Color blanco para el separador */
  margin: 0 8px;           /* Espacio alrededor del separador */
}

/* Eliminar el separador en el último elemento de la barra de navegación */
.navbar li:last-child::after {
  content: '';             /* Elimina el separador en el último elemento */
}

/* Eliminar el separador en los elementos del dropdown */
.dropdown li::after {
  content: '';             /* Sin separador en las opciones del dropdown */
}

/* Estilo del separador (>) en cada opción del dropdown */
.dropdown li::before {
  content: '> ';           /* Mostrar separador en las opciones del dropdown */
  color: white;            /* Color blanco para el separador */
}

/* Estilo de cada opción en el dropdown */
.dropdown li {
  text-align: left;        /* Alinear el texto a la izquierda */
  padding: 8px 12px;      /* Espaciado interno de cada opción */
  margin: 0;              /* Sin margen para las opciones */
}

/* Estilo de los enlaces en el dropdown */
.dropdown li a {
  color: #ffffff;          /* Color blanco para los enlaces del dropdown */
  text-decoration: none;   /* Sin subrayado para los enlaces del dropdown */
}

/* Efecto al pasar el mouse sobre los enlaces del dropdown */
.dropdown li a:hover {
  background-color: #2d3182; /* Color de fondo al pasar el mouse en el dropdown */
}
