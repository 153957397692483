.subtitulo {
  font: bold 100% sans-serif;
  background: #2d3182;
  border-radius: 0.25em;
  color: white;
  padding: 0.5em 0;
  padding-left: 10px;
  text-align: left;
  width: 100% !important;
}

.boton {
  background-color: #2d3182 !important;
  border-color: white !important;
}

.acordion {
  width: 98% !important;
}
