.boton {
  background-color: #2d3182 !important;
  border-color: white !important;
}
.containerTable {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.timelineArea {
  position: relative;
  z-index: 1;
}
.singleTimelineArea {
  position: relative;
  z-index: 1;
  padding-left: 180px;
}
@media only screen and (max-width: 575px) {
  .singleTimelineArea {
    padding-left: 100px;
  }
}
.singleTimelineArea .timelineDate {
  position: absolute;
  width: 180px;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-right: 60px;
}
@media only screen and (max-width: 575px) {
  .singleTimelineArea .timelineDate {
    width: 100px;
  }
}
.singleTimelineArea .timelineDate::after {
  position: absolute;
  width: 3px;
  height: 100%;
  content: "";
  background-color: #ebebeb;
  top: 0;
  right: 30px;
  z-index: 1;
}
.singleTimelineArea .timelineDate::before {
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #1484b4;
  content: "";
  top: 50%;
  right: 26px;
  z-index: 5;
  margin-top: -5.5px;
}
.singleTimelineArea .timelineDate p {
  margin-bottom: 0;
  color: #020710;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
}
.subtitulo {
  font: bold 100% sans-serif;
  background: #2d3182;
  border-radius: 0.25em;
  color: white;
  padding: 0.5em 0;
  padding-left: 10px;
  text-align: left;
  width: 100% !important;
}
