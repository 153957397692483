.cerrarsesion {
  border: 2px solid #303484;
  background-color: white;
  padding: 3px 9px;
  font-weight: bold;
}
.dropItem a {
  color: white;
  font-weight: bold;
}
.dropdownItem {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  color: var(--bs-dropdown-link-color);
}
.navbar {
  background: linear-gradient(90deg, #080404 0%, #303484 50%, #080404 100%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
