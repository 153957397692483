.cabecera>tr>th {
  background: #c8cae1 !important;
  color: #51559c !important;
}

.alignMiddle {
  text-align: center;
  vertical-align: middle;
}

.columna {
  min-width: 200px;
}

.titulo {
  text-decoration: unset !important;
  color: inherit !important;
  font-weight: bold !important;
  vertical-align: bottom !important;
}